.containerImg {
  margin-top: 5rem;
  width: 100%;
  height: 65vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}
.heading {
  font-size: 4.8rem;
}

.phone {
  margin-top: 3rem;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.icon {
  font-size: 4rem;
  color: black;
}

.text {
  letter-spacing: 1px;
}

#id {
  color: white;
}
#id:hover,
#id:active {
  color: var(--color-primary-dark);
}

@media (max-width: 500px) {
  .heading {
    font-size: 3rem;
  }
}
