footer {
  padding: 2rem 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.fb {
  color: #4267b2;
  font-size: 5rem;
}

.fb:hover {
  color: var(--color-primary);
}

@media (max-width: 800px) {
  footer {
    padding: 2rem 1rem;
  }
}
