@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Belanosima:wght@600;700&family=Caveat:wght@600;700&family=Lato:wght@300;400;700;900&display=swap");

:root {
  --color-primary: #fcc419;
  --color-primary-dark: #fab005;
  --color-text: #2b3035;
  --color-text-dark: #adb5bd;
  --color-text-light: #f4f6f7;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Lato", sans-serif;
  color: var(--color-text);
  font-size: 1.6rem;
  line-height: 1.6;
  letter-spacing: 1px;
  overflow-x: hidden;
}

a:link,
a:visited {
  color: var(--color-text);
  text-decoration: none;
}

a:hover,
a:active {
  color: var(--color-primary-dark);
  scale: 1.03;
}

/* ICONS GLOBAL */

.icon-small {
  color: var(--color-primary);
  font-size: 2.4rem;
}

/*sizing of leaflet map ocntainer */
.leaflet-container {
  height: 80vh;
  z-index: 3;
}
