.header {
  font-size: 3.2rem;
  letter-spacing: 3px;
  margin-top: 3rem;
  text-align: center;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 9rem;
  justify-items: center;
  align-items: start;
  row-gap: 5rem;
  margin-top: 10rem;
}

.icon {
  font-size: 5rem;
  color: var(--color-primary);
}

.paragraph {
  margin-top: 6px;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 2rem;
}

@media (max-width: 700px) {
  .container {
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
  }
}
