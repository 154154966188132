.heroWrapper {
  position: relative;
}

.containerImg {
  width: 100%;
  height: 85vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -10rem;
}
.companyName {
  color: var(--color-text-light);
  text-transform: uppercase;
}

.logoText {
  font-size: 5rem;
  font-family: "Caveat", cursive;
  font-weight: bold;
  color: var(--color-text-light);
}

.primary {
  margin-top: 2rem;
  font-weight: 900;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  z-index: 2 !important;
}

.link:hover,
.link:active {
  color: aliceblue;
}

.link:visited {
  color: black;
}

@media (max-width: 600px) {
  .companyName {
    font-size: 2.4rem;
  }
  .logoText {
    font-size: 3.6rem;
  }
}

@media (max-width: 500px) {
  .companyName {
    font-size: 1.6rem;
  }
  .logoText {
    font-size: 5rem;
  }
}
