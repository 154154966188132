.img {
  width: 100%;
  height: auto;
}

.container {
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  justify-items: start;
  padding: 3.6rem;
  margin-top: 5rem;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 1rem;
}
.heading {
  font-size: 3rem;
}

.category {
  opacity: 0.7;
}

.price {
  color: var(--color-primary);
  font-size: 4.6rem;
}

.margin {
  margin-top: 2rem;
}
.list {
  list-style: none;
  margin-top: 3rem;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 3rem;
  color: var(--color-primary);
}

.imageContainer {
  padding: 3rem 2rem;
}

.slogan {
  border-bottom: 1px solid var(--color-primary);
  margin-bottom: 3rem;
  overflow-x: hidden;
}
.button {
  background-color: var(--color-primary) !important;
  font-size: 2rem !important;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 5rem;
}

.link {
  color: var(--color-text);
}

.link:hover,
.link:active {
  color: aliceblue;
}

@media (max-width: 950px) {
  .container {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
  .card {
    width: 100%;
  }
  .heading {
    font-size: 2.5rem;
  }

  .button {
    font-size: 1.6rem !important;
  }
}

@media (max-width: 500px) {
  .imageContainer {
    scale: 1.04;
  }
}
