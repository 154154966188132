.mapContainer {
  position: relative;
  margin-top: 8rem;
}

.tooltip {
  padding: 1rem;
  font-size: 1.2rem;
  border-radius: 5px;
}

.address {
  background-color: var(--color-primary);
  border: none;
  padding: 1rem 2rem;
  margin-left: 10px;
}

.address:hover {
  background-color: var(--color-primary-dark);
  cursor: pointer;
}

.mapHeader {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, -100%);
  background-color: white;
  z-index: 3;
  padding: 2rem 14rem;
  font-size: 2rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-transform: uppercase;
}

@media (max-width: 1050px) {
  .mapHeader {
    font-size: 1.7rem;
    padding: 2rem 4rem;
  }
}
@media (max-width: 600px) {
  .mapHeader {
    font-size: 1.6rem;
    padding: 1rem 2rem;
  }
}
@media (max-width: 500px) {
  .mapHeader {
    font-size: 1rem;
    padding: 1rem 1rem;
  }
}
