.container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: grey;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 100000;
}
.cookieButton {
  letter-spacing: 3px !important;
  padding: 1.6rem 3rem !important;
}

.cookieButton:hover {
  color: white;
}

@media (max-width: 600px) {
  .container {
    padding: 1.5rem;
    gap: 1.6rem;
  }
  .cookieButton {
    letter-spacing: 2px !important;
    padding: 1rem 2rem !important;
  }

  .cookieText {
    font-size: 1.2rem;
  }
}
