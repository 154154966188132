/* Normal styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.icon-small {
  font-size: 1.5rem;
}

.contactItem {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.test {
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 2rem;
}

.button {
  display: none;
}

.phone {
  display: block;
}

.toggleButton {
  display: none;
}

/* Responsive styles */
@media (max-width: 900px) {
  .test {
    flex-direction: column;
    align-items: start;
    gap: 0.6rem;
  }

  .contactInfo {
    margin-top: 1rem;
  }
}

@media (max-width: 600px) {
  .button {
    display: block;
    align-items: start;
    justify-content: start;
  }

  .test {
    display: none;
  }

  .containerLogo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .toggleButton {
    position: fixed;
    top: 30px;
    left: 20px;
    display: block;
    z-index: 4;
    cursor: pointer;
    font-size: 2rem;
    color: black;
    background-color: transparent;
    border: none;
    outline: none;
  }

  .phone {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 30vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 2rem;
    box-sizing: border-box;
    z-index: 3;
    font-size: 1.4rem;
    padding-top: 70px;
  }

  .icon-small {
    margin-top: 14rem;
  }
}
