.imgContainer {
  position: relative;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.closeButton {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 10px;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--color-primary);
  border: none;
  color: black;
  z-index: 999;
}

.closeButton:hover {
  color: aliceblue;
}

.offer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 5rem;
}

.text {
  font-size: 5rem;
  background-color: rgba(252, 196, 25, 0.7);
}

@media (max-width: 500px) {
  .text {
    font-size: 4.6rem;
  }
}
