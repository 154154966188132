.testHeader {
  font-size: 3.2rem;
  letter-spacing: 3px;
  padding: 5rem 1rem 1rem 1rem;
  text-align: center;
}

.testImg {
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(
      70deg,
      rgba(250, 176, 5, 1),
      rgba(0, 0, 0, 0.9)
    ),
    url("../assets/test.jpg");
  opacity: 1;
  text-align: center;
  color: whitesmoke;
}

.testGrid {
  padding: 3.6rem;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(2, 25vh);
  grid-gap: 2rem;
}

.testPhoto {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.imgContainer {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 3;
  box-shadow: rgba(0, 0, 0, 0.882) 0px 5px 15px;
}

.paragraph {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 3;
  font-size: 1.8rem;
  text-align: justify;
  align-self: center;
}

@media (max-width: 700px) {
  .testGrid {
    display: flex;
    margin-top: 3rem;
    grid-gap: 0;
  }
  .testPhoto {
    display: none;
  }
}
